@import './variables.scss';

* {
    font-size: 15px;
    font-weight: 300;
}
.body{
    background-color: $background;
    width: 100vw;
    height: 100vh;
    overflow: auto;
}
.screen {
    background-color: $white;
    width: 100%;
    height: 100%;
}
.text-error {
    color: $danger;
}
.text-click {
    color: $info;
    cursor: pointer;
    text-decoration: underline;
}
i {
    text-decoration: none;
}
.loading-whole-page {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 9999999999999;
    background: rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
}
.input-group-text {
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-label {
    margin-top: 2px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 14px;
}
.require-star {
    color: $danger;
    font-weight: bold;
}