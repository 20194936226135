.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 93vh;
    margin: 10px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
