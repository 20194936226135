.box-avatar-container {
    display: flex;

    .wrapper {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        border: 1px solid #d2d2d2;
        border-radius: 5px;

        .box-avatar {

            max-width: 100%;
            max-height: 100%;
        }
    }

}
.align-content {
    display: flex;
    align-content: center;
}
.label-content {
    margin-right: 10px;
}
.modal-container {
    position: relative;
}

.del-modal {
    left: 350px;
    position: absolute;
    top: -20px;
    font-size: 25px;
    color: red;
}
