@import './variables.scss';
@import "~bootstrap/scss/bootstrap.scss";

// overide boostrap

// .btn-primary {
//     background-color: $primary;
//     border-color: $primary;
// }
// .btn-primary:hover {
//     background-color: $primary;
//     border-color: $primary-darker-1;
// }