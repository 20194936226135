@import '../../../scss/variables.scss';
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    margin: 10px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.app-btn {
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin: 15px;
    border-radius: 7px;
    min-width: 182px;
    box-shadow: 2px 2px 10px -3px $primary;
    cursor: pointer;
    &:hover {
        box-shadow: 2px 2px 15px -3px $primary; 
    }
}
.lw-shadow {
    box-shadow: 2px 2px 10px -3px $danger;
    &:hover {
        box-shadow: 2px 2px 15px -3px $danger; 
    }
}
.app-text {
    text-align: center;
    margin-top: 5px;
    font-weight: 400;
}
.account-info {
    cursor: pointer;
}