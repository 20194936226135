$primary:#540296;
$primary-darker-1:#370163;
$info: #2eadd3;
$highlight: #FD7F35;
$secondary: #707070;
$background: #e4e5e6;
$white: #fff;
$black: #000;
$warning: #ffc107;
$warning-darker-1: #c69500;
$danger: #ff0000;
$success: #28a745;

// bootstrap root config
// --blue: #007bff;
// --indigo: #6610f2;
// --purple: #6f42c1;
// --pink: #e83e8c;
// --red: #dc3545;
// --orange: #fd7e14;
// --yellow: #ffc107;
// --green: #28a745;
// --teal: #20c997;
// --cyan: #17a2b8;
// --white: #fff;
// --gray: #6c757d;
// --gray-dark: #343a40;
// --primary: #007bff;
// --secondary: #6c757d;
// --success: #28a745;
// --info: #17a2b8;
// --warning: #ffc107;
// --danger: #dc3545;
// --light: #f8f9fa;
// --dark: #343a40;
// --breakpoint-xs: 0;
// --breakpoint-sm: 576px;
// --breakpoint-md: 768px;
// --breakpoint-lg: 992px;
// --breakpoint-xl: 1200px;
// --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

